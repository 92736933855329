import React, { useContext } from 'react'
import { DataContext } from '../context'
import Countdown from 'react-countdown';
import { Container, Row, Col } from 'react-bootstrap';
import Loading from '../components/Loading';
import Title from './Title'


// Random component

function Affiliate() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    const context = useContext(DataContext);
    const { comingSoon } = context.allData

    if(!comingSoon) {
        return <Loading />
    }
    return (
        <section className="coming-soon min-vh-aff vw-100">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>

                        <div className="coming-soon-content text-white">

                            <h2>推薦朋友購買，免費使用服務</h2>

                            <br />

                            <p>只需推薦您的朋友使用我們的服務，您就能輕鬆賺取 20% 的佣金獎勵。<br />每當您的朋友通過您的推薦鏈接購買成功，您就能獲得佣金。這些佣金可用於購買和續費我們的全部服務。<br />立即分享，讓更多人享受我們的優質服務，同時讓您能夠免費使用我們的服務。</p>
                            
                            <div>
                                
                                <div className="btn-wrap">
                                    <span></span>
                                    <a href="https://my.feiyijiasu.com/affiliates.php" className="btn">立即加入</a>
                                </div>

                            </div>
                        </div>

                    </Col>
                    <Col lg={6}>

                        <div className="coming-soon-img">
                            <img src="images/media/affiliate.png" alt="" />
                        </div>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Affiliate
