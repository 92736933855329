import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaComment,
  FaCommentAlt,
  FaWindows,
  FaAndroid,
  FaApple,
  FaMapMarkerAlt,
  FaCalendarAlt,
} from "react-icons/fa";
import Loading from "./Loading";
import { Container, Row, Col } from "react-bootstrap";

function Footer({ data, styleTwo, styleThree }) {
  // let publicUrl = process.env.PUBLIC_URL + '/'
  if (!data) {
    return <Loading />;
  }
  const {
    widgetAddress,
    widgetServices,
    widgetSolutions,
    widgetRecentPost,
    socials,
    copyrightText,
  } = data;

  return (
    <footer
      className={`footer ${
        styleTwo ? "style--two" : styleThree ? "style--three" : ""
      }`}
    >
      <div className="footer-bg-shape"></div>

      <div className="footer-top">
        <Container>
          <Row>
            <Col lg={3} md={6}>
              <div className="widget widget_nav_menu">
                <h3 className="widget-title">{widgetSolutions.title}</h3>
                <div className="menu-dvpn-container">
                  <ul className="menu">
                    {widgetSolutions.widgetLink.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.link}>{item.text}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} md={6}>
              <div className="widget widget_contact">
                <h3 className="widget-title">{widgetAddress.title}</h3>
                <ul>
                  <li>
                    <span className="icon">
                      <FaComment />
                    </span>
                    <a href="https://tawk.to/chat/6694cb6cbecc2fed6924e5cd/1i2qjl8j0" target="_blank">
                    線上客服
                    </a>
                  </li>
                  <li>
                    <span className="icon">
                      <FaCommentAlt />
                    </span>
                    <a href={`${widgetAddress.phone.link}`}>
                      {widgetAddress.phone.text}
                    </a>
                  </li>
                  <li>
                    <span className="icon">
                      <FaEnvelope />
                    </span>
                    <a href={`mailto:${widgetAddress.mail.link}`}>
                      {widgetAddress.mail.text}
                    </a>
                  </li>
                </ul>

              </div>
            </Col>

            <Col lg={4} md={6}>
              <div className="widget widget_contact">
                <h3 className="widget-title">使用說明</h3>
                <ul>
                  <li>
                    <span className="icon">
                      <FaWindows />
                    </span>
                    <a href="https://help.feiyijiasu.com/windows-v2rayn/" target="_blank">
                    Windows
                    </a>
                  </li>
                  <li>
                    <span className="icon">
                      <FaAndroid />
                    </span>
                    <a href="https://help.feiyijiasu.com/android-v2rayng/" target="_blank">
                      Android
                    </a>
                  </li>
                  <li>
                    <span className="icon">
                      <FaApple />
                    </span>
                    <a href="https://help.feiyijiasu.com/ios-shadowrocket/" target="_blank">
                      iOS
                    </a>
                  </li>
                  <li>
                    <span className="icon">
                      <FaApple />
                    </span>
                    <a href="https://help.feiyijiasu.com/macos-v2rayu/" target="_blank">
                      macOS
                    </a>
                  </li>
                </ul>

              </div>
            </Col>

          </Row>
        </Container>
      </div>

      <div className="footer-bottom">
        <Container>
          <Row>
            <Col>
              <div className="footer-bottom-text text-center">
                Copyright © {new Date().getFullYear()}{" "}
                <a href={copyrightText.link} className="bold">
                  {copyrightText.linkText}
                </a>{" "}
                {copyrightText.lastText}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
