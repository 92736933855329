import React from 'react'
import Loading from './Loading'

import {
  FaWindows,
  FaAndroid,
  FaApple,
  FaLinux,
} from "react-icons/fa";

function App({data}) {

    let publicUrl = process.env.PUBLIC_URL + '/'
    if(!data) {
        return <Loading />
    }
    return (
        <section className="app-section section-bg">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="app-img">
                            <img src={publicUrl + data.img.url} alt={data.img.alt} />
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="app-content">
                            <div className="section-title style_2 text-left title-shape title-shape-sky-blue">
                                <h2>{data.title}</h2>
                                <p>{data.desc}</p>
                            </div>
                            <div className="download-btns mt-n3">

                                            <a href="https://help.feiyijiasu.com/windows-v2rayn/" className="download-btn d-inline-block mb-3" target="_blank">
                                                <FaWindows style={{ fontSize: '4rem' }} />
                                            </a>
                                            <a href="https://help.feiyijiasu.com/android-v2rayng/" className="download-btn d-inline-block mb-3" target="_blank">
                                                <FaAndroid style={{ fontSize: '4rem' }} />
                                            </a>
                                            <a href="https://help.feiyijiasu.com/macos-v2rayu/" className="download-btn d-inline-block mb-3" target="_blank">
                                                <FaApple style={{ fontSize: '4rem' }} />
                                            </a>
                                            <a href="https://help.feiyijiasu.com/ios-shadowrocket/" className="download-btn d-inline-block mb-3" target="_blank">
                                                <FaApple style={{ fontSize: '4rem' }} />
                                            </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default App
